import React from "react";
import Nav from "./Nav";
import Footer from "../../common/Footer";
import ScrollTop from "../../common/ScrollTop";

const Layout = ({ children, currentCategory, categoriesList }) => {
  return (
    <div>
      <Nav currentCategory={currentCategory} categoriesList={categoriesList} />
      {children}
      <Footer />
      <ScrollTop />
    </div>
  );
};

export default Layout;
