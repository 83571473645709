import React, { useState } from "react";
import { navigate } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from "gatsby";

const Nav = ({ currentCategory, categoriesList }) => {
  const data = useStaticQuery(graphql`
  {
    strapiGrootanSiteLogos {
      image {
        childImageSharp {
          gatsbyImageData(quality: 20, placeholder: NONE)
        }
      }
    }
  }
`);
  const { image } = data.strapiGrootanSiteLogos;
  const createdImage = getImage(image);
  const [menuDropdown, setMenuDropdown] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [mainDropdown, setMainDropdown] = useState(false);
  const showMenu = () => {
    setShowDropdown(true);
  };
  const hideMenu = () => {
    setShowDropdown(false);
  };
  const currentTitle = categoriesList.filter(
    (categoryData) => categoryData.slug === currentCategory
  );
  return (
    <nav
      className=" blog-nav st-nav navbar main-nav navigation fixed-top dark-link"
      id="main-nav"
    >
      <div className="container px-0">
        <ul className="st-nav-menu nav navbar-nav ">
          <li className="st-nav-section nav-item d-flex align-items-center">
            <Link
              to="/"
              className="tagmanager-logo-home-blog navbar-brand"
              title={`home page link`}
            >
              <GatsbyImage image={createdImage} alt="grootan logo" />
            </Link>
          </li>
          {currentCategory !== "all" ? (
            <li className="st-nav-section  st-nav-primary nav-item ml-4">
              <div
                className="tagmanager-home-blog-nav st-root-link nav-link font-regular cursor-pointer"
                onClick={() => {
                  navigate("/blogs");
                }}
              >
                Home
              </div>
            </li>
          ) : (
            ""
          )}
          <li
            className={`st-nav-section  st-nav-primary nav-item dropdown-nav-container desktop  ${
              mainDropdown ? "dropdown-on" : ""
            }`}
          >
            <div
              className={`st-root-link nav-link 
              dropdown-nav-title desktop bg-transparent ${mainDropdown ? "active" : ""}`}
              onClick={() => {
                setMainDropdown((prev) => !prev);
              }}
            >
              {currentCategory === "all" ? (
                <span className="font-regular">All Categories</span>
              ) : (
                <span className="font-regular">{currentTitle[0].title}</span>
              )}
              <FontAwesomeIcon
                icon={faAngleDown}
                style={{
                  display: "inline-block",
                  marginLeft: 10,
                  transition: "all 0.2s ease-out"
                }}
              />
            </div>
            <ul className="nav-sub-menu blog">
              {currentCategory !== "all" ? (
                <li
                  className="tagmanager-blog-category nav-sub-item blog"
                  onClick={() => {
                    navigate("/blogs");
                  }}
                >
                  All Categories
                </li>
              ) : null}
              {categoriesList.map((categoryData, index) =>
                categoryData.slug !== currentCategory ? (
                  <li
                    className={`tagmanager-blog-category nav-sub-item blog`}
                    key={index}
                    onClick={() => {
                      navigate("/topics/" + categoryData.slug);
                    }}
                  >
                    {categoryData.title}
                  </li>
                ) : null
              )}
            </ul>
          </li>
          {/* Mobile Nav */}
          <li
            className={`st-nav-section  st-nav-mobile nav-item ${
              showDropdown ? "st-popup-active" : ""
            }`}
          >
            <button
              className="st-root-link navbar-toggler"
              type="button"
              onClick={showMenu}
            >
              <span className="icon-bar"></span>{" "}
              <span className="icon-bar"></span>{" "}
              <span className="icon-bar"></span>
            </button>
            {showDropdown ? (
              <div className="st-popup">
                <div className="st-popup-container">
                  <span
                    className="st-popup-close-button"
                    onClick={() => {
                      setMenuDropdown(false);
                      hideMenu();
                    }}
                  >
                    Close
                  </span>

                  <div className="st-dropdown-content-group">
                    {currentCategory !== "all" ? (
                      <li
                        className={`dropdown-nav-container mobile ${
                          menuDropdown ? "dropdown-on" : ""
                        }`}
                      >
                        <div
                          className="tagmanager-home-blog-nav dropdown-title-mobile"
                          onClick={() => {
                            navigate("/blogs");
                          }}
                        >
                          Home
                        </div>
                      </li>
                    ) : (
                      ""
                    )}
                    <li
                      className={`dropdown-nav-container mobile ${
                        menuDropdown ? "dropdown-on" : ""
                      }`}
                    >
                      <div
                        className="dropdown-title-mobile"
                        onClick={() => {
                          setMenuDropdown((prev) => !prev);
                        }}
                      >
                        Categories
                        <i className="fas fa-angle-down angle"></i>
                      </div>
                      <ul className="mobile-sub-menu-ul blog-submenu-container">
                        {/* categories */}
                        <li
                          className="tagmanager-blog-category mobile-sub-menu-li"
                          onClick={() => {
                            navigate("/blogs");
                          }}
                        >
                          All Categories
                        </li>
                        {categoriesList.map((categoryData, index) => (
                          <li
                            className={`tagmanager-blog-category mobile-sub-menu-li`}
                            key={index}
                            onClick={() => {
                              navigate("/topics/" + categoryData.slug);
                            }}
                          >
                            {categoryData.title}
                          </li>
                        ))}
                      </ul>
                    </li>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Nav;
